import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { serverPage } from 'dataFixed';
import { SetListData } from 'interface/InterfaceData';

const root = ReactDOM.createRoot(document.getElementById('root'));

const data = new FormData();
data.append('showData', 'ListData');

Axios({
    url     : serverPage + '/php/list_data.php?acao=list_inf',
    mode    : 'no-cors',
    method  : 'POST',
    data    : data
})
.then(response => {
    SetListData(response.data);
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});