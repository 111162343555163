
// export const serverPage = 'http://localhost/metazonia_vr/public';
export const serverPage = '';

export const menuList = [    
            {
                "id": 1,
                "name": "Térreo",
                "state": false,
                "submenu": [
                    {
                        "id": 1,
                        "name": "Praça",
                        "state": false,
                        "submenu": [
                            {
                                "id": 1,
                                "id_xml": "terreo_praca_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 2,
                        "name": "Recepção",
                        "state": false,
                        "submenu": [
                            {
                                "id": 2,
                                "id_xml": "terreo_recepcao_agendamento",
                                "name": "Agendamento",
                                "state": false
                            },
                            {
                                "id": 3,
                                "id_xml": "terreo_recepcao_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 3,
                        "name": "Casarão",
                        "state": false,
                        "submenu": [
                            {
                                "id": 4,
                                "id_xml": "terreo_casarao_premiacoes",
                                "name": "Premiações",
                                "state": false
                            },
                            {
                                "id": 5,
                                "id_xml": "terreo_casarao_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 4,
                        "name": "História",
                        "state": false,
                        "submenu": [
                            {
                                "id": 6,
                                "id_xml": "terreo_historia_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 5,
                        "name": "Metazonia",
                        "state": false,
                        "submenu": [
                            {
                                "id": 7,
                                "id_xml": "terreo_metazonia_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 17,
                        "name": "Prefeitura",
                        "state": false,
                        "submenu": [
                            {
                                "id": 19,
                                "id_xml": "terreo_prefeitura_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 6,
                        "name": "Sala 1",
                        "state": false,
                        "submenu": [
                            {
                                "id": 8,
                                "id_xml": "terreo_sala1_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 7,
                        "name": "Sala 2",
                        "state": false,
                        "submenu": [
                            {
                                "id": 9,
                                "id_xml": "terreo_sala2_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 8,
                        "name": "Sala 3",
                        "state": false,
                        "submenu": [
                            {
                                "id": 10,
                                "id_xml": "terreo_sala3_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    }
                ]
            },
            {
                "id": 2,
                "name": "1° Andar",
                "state": false,
                "submenu": [
                    {
                        "id": 9,
                        "name": "Coworking",
                        "state": false,
                        "submenu": [
                            {
                                "id": 11,
                                "id_xml": "1andar_coworking_sobre1",
                                "id_xml_2": "1andar_coworking_sobre2",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 10,
                        "name": "Semtepi",
                        "state": false,
                        "submenu": [
                            {
                                "id": 12,
                                "id_xml": "1andar_semtepi_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 11,
                        "name": "Sala 4",
                        "state": false,
                        "submenu": [
                            {
                                "id": 13,
                                "id_xml": "1andar_sala4_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 12,
                        "name": "Sala 5",
                        "state": false,
                        "submenu": [
                            {
                                "id": 14,
                                "id_xml": "1andar_sala5_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 13,
                        "name": "Sala 6",
                        "state": false,
                        "submenu": [
                            {
                                "id": 15,
                                "id_xml": "1andar_sala6_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    }
                ]
            },
            {
                "id": 3,
                "name" : "2° Andar",
                "state" : false,
                "submenu": [
                    {
                        "id": 14,
                        "name": "Porto",
                        "state": false,
                        "submenu": [
                            {
                                "id": 16,
                                "id_xml": "2andar_porto_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 15,
                        "name": "Teatro",
                        "state": false,
                        "submenu": [
                            {
                                "id": 17,
                                "id_xml": "2andar_teatro_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    },
                    {
                        "id": 16,
                        "name": "Ponte",
                        "state": false,
                        "submenu": [
                            {
                                "id": 18,
                                "id_xml": "2andar_ponte_sobre",
                                "name": "Sobre",
                                "state": false
                            }
                        ]
                    }
                ]
            }
]