
import React, { useState, useEffect } from "react";

import { SetListPag, GetListPag, RegisterListPag } from "interface/InterfacePag";

import Cookies from 'universal-cookie';

import FixedTopic from "components/FixedTopic";
import MenuLeft from 'components/MenuLeft';
import ContentList from 'components/ContentList';

function App() {

    const cookies = new Cookies();  
    const [ currentPage, setCurrentPage ] = useState(GetListPag('page'));

    useEffect(()=>{
        RegisterListPag('page', setCurrentPage);
        pageStart();
    }, []);

    // Checked cookies
    function pageStart(){ 

        // Page
        if(cookies.get('metazonia_vr_page')){  
            let cookiesPages = cookies.get('metazonia_vr_page'); 
            SetListPag('currentPage', cookiesPages); 

            let cookiesIdPages = cookies.get('metazonia_id_page'); 
            SetListPag('currentRegister', cookiesIdPages); 
            
        }else {
            cookies.set('metazonia_vr_page', 'index', '/', 'localhost/');
            let cookiesPages = cookies.get('metazonia_vr_page');
            SetListPag('currentPage', cookiesPages); 
            
            cookies.set('metazonia_id_page', {"idMenu" : 0, "idSubMenu" : 0, "idSubMenu_sub" : 0}, '/', 'localhost/');
            let cookiesIdPages = cookies.get('metazonia_id_page');
            SetListPag('currentRegister', cookiesIdPages); 
        }
    }

    return (
        <div className="Contents">
            <FixedTopic />
            <div className="list_data">
                <MenuLeft />
                <ContentList />
            </div>
        </div>
    );
}

export default App;
