
import { useState, useEffect } from "react";

import './FixedTopic.css';

import Logo from 'logo.svg';

import { SvgSetaRight } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/InterfacePag";

export default function FixedTopic(){
    
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));

    const [ nameClicked, setNameClicked ] = useState([]);

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);
    }, []);

    useEffect(()=>{
        if(pageState.idMenu !=0){
            
            if(pageState.idSubMenu !=0){
                if(pageState.idSubMenu_sub ==0){
                }else {
                }
            }else {
            }
        }
    }, [pageState]);
    
    return(        
        <div className="ListTopic">
            <div className="logotipo">
                <img alt="logotipo" src={ Logo } className="logo" />
            </div>
            <div className="show_name_menu">
                <div>Dashboard</div>
                { 
                    pageClicked == 'index' ? null : 
                    <>
                        <SvgSetaRight color="#2D3C53" className="svg_opt" />
                        { pageClicked }
                    </>
                    // <>
                    //     {
                    //         nameClicked.map((key, index)=>{
                    //             return(
                    //                 <div key={ index } style={ { display: "flex" } }>                             
                    //                     <SvgSetaRight color="#2D3C53" className="svg_opt" />
                    //                     { key }
                    //                 </div>  
                    //             )
                    //         })
                    //     }
                    // </> 
                }
            </div>
        </div>
    )
}