
import { useState, useEffect } from "react";

import './MenuLeft.css';

import { GetData } from "interface/InterfaceData";
import { SetListPag, GetListPag, RegisterListPag } from "interface/InterfacePag";

import axios from "axios";
import { SvgSetaRight } from "components/SvgFile";
import { menuList } from "dataFixed";

export default function MenuLeft(){
    
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetData('menu'));
    const [ stateMenu, setStateMenu ]     = useState(menuList);

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);
    }, []);

    // click page
    function ClickPage(value, index){
        SetListPag('currentPage', value);
    }

    // id page click
    function IdPageClick(idMenu, idSubMenu, idSubMenuSub){
        SetListPag('currentRegister', {"idMenu" : idMenu, "idSubMenu" : idSubMenu, "idSubMenu_sub" : idSubMenuSub});  
    }

    function UploadXml(file){
        const data = new FormData();
        data.append('file', file);
        data.append('showData', 'update_file');

        axios({
            url     : 'http://localhost/metazonia_vr/public/php/list_data.php?acao=update_file',
            mode    : 'no-cors',
            method  : 'POST',
            data    : data
        })   
        .then(response => {
        })
    }

    return(
        <div className="ListMenu">
            <div className="list_menu_left">
                <div>                    
                    <div>
                        <label>
                            <div className="menu_name upload_xml">
                                <div>
                                    Enviar arquivo xml
                                </div>
                            </div>
                            <input type="file" onChange={ (e)=>{ UploadXml(e.target.files[0]) } } accept="application/xml"/>
                        </label>
                    </div>
                </div>
                {
                    stateMenu.map((menu, index_1)=>{
                        return(
                            <div key={ index_1 }>
                                <div className={ menu.id == pageState.idMenu ? "menu_name active" : "menu_name" } onClick={ ()=>{ ClickPage(menu.name, menu.id); IdPageClick(menu.id, 0, 0) } }>
                                    <div>{ menu.name }</div>
                                    <div>
                                        { 
                                            menu.id == pageState.idMenu ? 
                                            <SvgSetaRight color="#FFFFFF" className="svg_opt icons_rotate" /> :
                                            <SvgSetaRight color="#FFFFFF" className="svg_opt" />
                                        }
                                    </div>
                                </div>
                                <div className={ menu.id == pageState.idMenu ? "list_sub_menu sub_menu_active" : "list_sub_menu" }>

                                    {
                                        menu.submenu.map((list_submenu, index_2)=>{
                                            return(
                                                <div key={ index_2 }>
                                                    <div className={ list_submenu.id == pageState.idSubMenu ? "menu_name active" : "menu_name" } onClick={ ()=>{ ClickPage(list_submenu.name, list_submenu.id); IdPageClick(menu.id, list_submenu.id, 0) } }>
                                                        <div>{ list_submenu.name }</div>
                                                        <div>
                                                            { 
                                                                list_submenu.id == pageState.idSubMenu ? 
                                                                <SvgSetaRight color="#FFFFFF" className="svg_opt icons_rotate" /> :
                                                                <SvgSetaRight color="#FFFFFF" className="svg_opt" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={ list_submenu.id == pageState.idSubMenu ? "list_sub_menu sub_menu_active" : "list_sub_menu" }>
                                                        {
                                                            list_submenu.submenu.map((list_submenusub, index_3)=>{
                                                                return(
                                                                    <div key={ index_3 }>
                                                                        <div className={ list_submenusub.id == pageState.idSubMenu_sub ? "menu_name active" : "menu_name" } onClick={ ()=>{ ClickPage(list_submenusub.name, list_submenusub.id); IdPageClick(menu.id, list_submenu.id, list_submenusub.id) } }>
                                                                            <div>{ list_submenusub.name }</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        )
                    })
                }
                {/* <div>
                    <div className={ pageClicked == 'terreo' ? "menu_name active" : "menu_name" } onClick={ ()=>{ ClickPage('terreo') } }>
                        <div>Térreo</div>
                        <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                    </div>
                    <div className={ pageClicked == 'terreo' ? "menu_name active" : "menu_name" }>
                        <div>
                            <div className={ pageClicked == 'praca' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_praca') } }>
                                <div>Praça</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                            
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_praca_sobre') } }>
                                    <div>Sobre</div>
                                    <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_recepcao') } }>
                                <div>Recepção</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                            
                                <div className={ pageClicked == 'agendamento' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_recepcao_agendamento') } }>
                                    <div>Agendamento</div>
                                </div>                      
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_recepcao_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_casarao') } }>
                                <div>Casarão</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                            
                                <div className={ pageClicked == 'agendamento' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_casarao_premiacoes') } }>
                                    <div>Premiações</div>
                                </div>                      
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_casarao_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_historia') } }>
                                <div>História</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                          
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_historia_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_metazonia') } }>
                                <div>Metazonia</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                          
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_metazonia_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_sala1') } }>
                                <div>Sala 1</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                          
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_sala1_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_sala2') } }>
                                <div>Sala 2</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                          
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_sala2_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_sala3') } }>
                                <div>Sala 3</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                          
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_sala3_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_prefeitura') } }>
                                <div>Prefeitura</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                          
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('terreo_prefeitura_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <div className={ pageClicked == '1andar' ? "menu_name active" : "menu_name" } onClick={ ()=>{ ClickPage('1andar') } }>
                        <div>1° Andar</div>
                        <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                    </div>
                    <div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1andar_semtepi') } }>
                                <div>Semtepi</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1ndar_semtepi_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1andar_sala4') } }>
                                <div>Sala 4</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1ndar_sala4_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1andar_sala5') } }>
                                <div>Sala 5</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1ndar_sala5_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1andar_sala6') } }>
                                <div>Sala 6</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1ndar_sala6_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1andar_coworking') } }>
                                <div>Coworking</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('1ndar_coworking_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <div className={ pageClicked == '2andar' ? "menu_name active" : "menu_name" } onClick={ ()=>{ ClickPage('2andar') } }>
                        <div>2° Andar</div>
                        <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                    </div>
                    <div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('2andar_porto') } }>
                                <div>Porto</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('2andar_porto_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('2andar_teatro') } }>
                                <div>Teatro</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('2andar_teatro_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={ pageClicked == 'recepcao' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('2andar_ponte') } }>
                                <div>Ponte</div>
                                <div><SvgSetaRight color="#FFFFFF" className="svg_opt" /></div>
                            </div>
                            <div>                     
                                <div className={ pageClicked == 'sobre' ? "list_sub_menu active" : "list_sub_menu" } onClick={ ()=>{ ClickPage('2andar_ponte_sobre') } }>
                                    <div>Sobre</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div>                    
                    <div>                        
                        <a href="./php/pano.xml" download>
                            <div className="menu_name upload_xml">
                                <div>
                                    Download do arquivo xml
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}