const ListData = {
    "menu" : {}
};

const NotifyData = {
    "menu" : []
};

export function SetListData(value){
    for (const key in value) {
        ListData[key] = value[key];
    } 
    console.log(value);
}

export function GetData(value){
    return ListData[value]; 
}

export function RegisterData(value, func){
    ListData[value].push(func);
}