
import Cookies from 'universal-cookie';
const cookies = new Cookies(); 

let ListPag = {
    "page" : 'login',
    "currentPage" : 'index',
    "currentRegister" : ''
};

let NotifyListPag = {
    "page"        : [],
    "currentPage" : [],
    "currentRegister" : []
}

export function GetListPag(key){
    return ListPag[key];   
}
export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });
    
    if(key == 'currentPage'){
        cookies.set('metazonia_vr_page', value, '/', 'localhost');
    }

    if(key == 'currentRegister'){
        cookies.set('metazonia_id_page', value, '/', 'localhost');
    }
}
export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = []; 
    }
    NotifyListPag[key].push(value);
}