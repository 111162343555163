
import { useState, useEffect } from "react";

import './ContentList.css';

import axios from "axios";

import { menuList, serverPage } from 'dataFixed';
import { GetData } from "interface/InterfaceData";
import { GetListPag, RegisterListPag } from "interface/InterfacePag";

import TextareaAutosize from 'react-textarea-autosize';

export default function ContentList(){
    
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetData('menu'));
    const [ stateMenu, setStateMenu ]     = useState(menuList);
    const [ showText, setShowText ]       = useState([{ "id": "", "description": ""}]);

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);
    }, []);

    useEffect(()=>{
        if(pageState){
            if(pageState.idMenu !=0){
                const dataMenu = stateMenu.find(item => item.id == pageState.idMenu);
                if(pageState.idSubMenu !=0){

                    const dataSubMenu = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
                    if(pageState.idSubMenu_sub !=0){ 
                        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);                        
                        const dataContents = listMenu.find(item => item.id == dataSubMenuSub.id_xml);
                        setShowText([dataContents]);
                    }

                }else {
                }
            }
        }
    }, [pageState]);

    function handleText(id, value){ 
        const newText = [...showText];
        newText[0].description = value;
        console.log(newText);
        setShowText(newText);
    }

    function RegisterData(){
        const data = new FormData();
        data.append('showData', 'edit_data');
        showText.forEach((element, index) => {
            data.append('id[]', element.id);
            data.append('description[]', element.description);
        });

        axios({
            url     : serverPage + '/php/list_data.php',
            mode    : 'no-cors',
            method  : 'POST',
            data    : data
        })   
        .then(response => {
            console.log(response.data);
        })
    }

    return(
        <>
            {
                pageState.idSubMenu_sub !=0 ? 
                <div className="ContentList">
                    <div className="register_data" onClick={ ()=>{ RegisterData() } }>
                        <div className="register_text">Registrar alterações</div>
                    </div>
                    <TextareaAutosize onChange={ (e)=>{ handleText(showText[0].id, e.target.value) } } value={ showText[0].description.replaceAll(/<br>/g,'\n') } />
                </div> : 
                <div className="ContentList">
                    <div className="title_inf">
                        É necessário selecionar um menu para alterar os dados
                    </div>
                </div>
            }
        </>
    );
}